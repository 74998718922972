import { StaticImage } from "gatsby-plugin-image"
import moment from "moment"
import React from "react"
import DefaultButton from "../buttons/DefaultButton"
import Image from "../Image"
import DefaultPattern from "../patterns/DefaultPattern"

function EventHero({ heading, content, date, hostedBy }) {
  // console.log(date)
  return (
    <section className="relative pt-32 md:pt-40 lg:pt-28 bg-slate">
      <div className="relative z-0 ">
        <div className="flex flex-col items-center lg:flex-row">
          <div className="container flex justify-end w-full lg:w-1/2">
            <div className="lg:max-w-[34rem] xl:max-w-[32rem] md:mr-12 lg:mr-[5.5rem] xl:mr-[4.5rem] lg:pl-6">
              <div className="flex text-16 text-white font-semibold uppercase opacity-70 tracking-[0.05em] leading-[1.109] mb-7 ">
                <div>{moment(date).format("MMMM DD YYYY h:mm a")}</div>
              </div>
              <h1 className="mb-6 text-white u-h3">{heading}</h1>
              <div
                className={` font-medium text-20  leading-[1.604] text-white`}
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
              <div className="mt-14">
                <div className="text-12 text-white font-semibold uppercase opacity-70 tracking-[0.05em] leading-[1.109]">
                  hosted by
                </div>
                <div className="flex mt-5 -ml-8 md:-ml-20">
                  {hostedBy?.map((host, i) => (
                    <div
                      key={`eventHost${i}`}
                      className="flex flex-col items-center pl-8 md:flex-row md:pl-20"
                    >
                      <Image
                        className="mr-5 max-w-[48px] "
                        image={host?.image}
                      />
                      <div className="mt-2 md:mt-0">
                        <div className="font-extrabold text-white uppercase text-14 ">
                          {host?.name}
                        </div>
                        <div className="font-medium text-14 text-beige">
                          {host?.title}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="relative w-full mt-12 lg:w-1/2 lg:mt-0">
            <div className="relative w-full h-full">
              <div className="flex items-center justify-center w-full h-full py-12 md:py-28">
                <div className="flex flex-col items-center justify-center px-8 md:px-16 z-10 bg-white py-12 md:py-8 lg:py-12 xl:py-28 rounded-2xl max-w-[478px] ">
                  <div className="mb-10 font-extrabold uppercase u-p2">
                    register
                  </div>
                  <div className="flex flex-col mb-6 w-[270px] md:w-[371px]">
                    <label className="text-12 uppercase font-bold tracking-[-0.02em] mb-3">
                      FIRST NAME
                    </label>
                    <input
                      className="border-b border-black focus:outline-none"
                      type="text"
                      placeholder="John"
                    />
                  </div>
                  <div className="flex flex-col mb-6 w-[270px] md:w-[371px]">
                    <label className="text-12 uppercase font-bold tracking-[-0.02em] mb-3">
                      FIRST NAME
                    </label>
                    <input
                      className="border-b border-black focus:outline-none"
                      type="text"
                      placeholder="John"
                    />
                  </div>
                  <div className="flex flex-col mb-6 w-[270px] md:w-[371px]">
                    <label className="text-12 uppercase font-bold tracking-[-0.02em] mb-3">
                      FIRST NAME
                    </label>
                    <input
                      className="border-b border-black focus:outline-none"
                      type="text"
                      placeholder="John"
                    />
                  </div>
                  <div className="flex flex-col mb-6 w-[270px] md:w-[371px]">
                    <label className="text-12 uppercase font-bold tracking-[-0.02em] mb-3">
                      FIRST NAME
                    </label>
                    <input
                      className="border-b border-black focus:outline-none"
                      type="text"
                      placeholder="John"
                    />
                  </div>
                  <DefaultButton
                    className="px-6 !py-4 text-11"
                    title="Download now"
                  />
                </div>
                <div className="absolute top-0 bottom-0 left-0 right-0 z-0 w-full h-full">
                  <StaticImage
                    objectFit="cover"
                    className="object-cover w-full h-full opacity-40"
                    src={"../../assets/images/hero-form-bg.jpg"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EventHero
