import React from "react"

function TextBlock({ content }) {
  return (
    <div
      className="mx-auto mb-16 prose text-black text-block"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

export default TextBlock
