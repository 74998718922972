import React, { useState } from "react"

function LinkedIn() {
  const [onHover, setOnHover] = useState(false)

  return (
    <svg
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      width="44"
      height="43"
      viewBox="0 0 44 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21.8981"
        cy="21.1217"
        r="20.6217"
        stroke={`${onHover ? "#000000" : "#E4E8EF"}`}
      />
      <path
        d="M27.9441 13.7461H15.0951C14.743 13.7461 14.479 14.0101 14.479 14.3621V27.2992C14.479 27.5632 14.743 27.8272 15.0951 27.8272H28.0321C28.3841 27.8272 28.6481 27.5632 28.6481 27.2112V14.3621C28.5601 14.0101 28.2961 13.7461 27.9441 13.7461ZM18.6153 25.7151H16.5912V19.0265H18.7033V25.7151H18.6153ZM17.6473 18.1464C16.9432 18.1464 16.4152 17.5304 16.4152 16.9143C16.4152 16.2103 16.9432 15.6822 17.6473 15.6822C18.3513 15.6822 18.8794 16.2103 18.8794 16.9143C18.7914 17.5304 18.2633 18.1464 17.6473 18.1464ZM26.448 25.7151H24.3358V22.4588C24.3358 21.6667 24.3358 20.6987 23.2797 20.6987C22.2236 20.6987 22.0476 21.5787 22.0476 22.4588V25.8031H19.9354V19.0265H21.9596V19.9066C22.2236 19.3785 22.9277 18.8505 23.8958 18.8505C26.0079 18.8505 26.36 20.2586 26.36 22.0188V25.7151H26.448Z"
        fill="#060606"
      />
    </svg>
  )
}

export default LinkedIn
