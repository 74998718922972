import { Link } from "gatsby"
import React from "react"
import Image from "../Image"

function RelatedPost({ heading, post }) {
  return (
    <Link to={post?.uri || "#"} className="max-w-[43rem] mx-auto ">
      <div className="flex flex-col items-center justify-between px-5 py-16 my-16 bg-black md:flex-row md: md:py-10 md:px-14">
        <div className="md:mr-32">
          <div className="font-normal text-white opacity-60">{heading}</div>
          <div className="font-semibold text-23 text-white leading-[1.235] mt-3">
            {post?.title}
          </div>
        </div>
        <Image
          className=" md:max-w-[130px] md:max-h-[130px] w-full h-full mt-12 md:mt-0"
          objectFit="cover"
          image={post?.featuredImage?.node}
        />
      </div>
    </Link>
  )
}

export default RelatedPost
