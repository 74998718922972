import React, { useState } from "react"

function Facebook() {
  const [onHover, setOnHover] = useState(false)

  return (
    <svg
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21.8092"
        cy="21.1217"
        r="20.6217"
        stroke={`${onHover ? "#000000" : "#E4E8EF"}`}
      />
      <path
        d="M28.2935 13.2012H14.7089C14.2405 13.2012 13.8892 13.5513 13.8892 14.0181V27.4978C13.8892 28.023 14.2405 28.3731 14.7089 28.3731H22.0282V22.4794H20.0374V20.2036H22.0282V18.5113C22.0282 16.5273 23.2578 15.477 24.9559 15.477C25.7757 15.477 26.5369 15.5353 26.7125 15.5937V17.636H25.4829C24.546 17.636 24.3704 18.1029 24.3704 18.7448V20.2036H26.654L26.3612 22.4794H24.3704V28.3731H28.2349C28.7034 28.3731 29.0547 28.023 29.0547 27.5562V14.0181C29.1133 13.5513 28.7619 13.2012 28.2935 13.2012Z"
        fill="#060606"
      />
    </svg>
  )
}

export default Facebook
