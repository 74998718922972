import React, { useState } from "react"

function Twitter() {
  const [onHover, setOnHover] = useState(false)

  return (
    <svg
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21.8536"
        cy="21.1217"
        r="20.6217"
        stroke={`${onHover ? "#000000" : "#E4E8EF"}`}
      />
      <path
        d="M18.6993 27.2845C24.396 27.2845 27.535 22.576 27.535 18.5069C27.535 18.3906 27.535 18.2162 27.535 18.1C28.1163 17.6349 28.6395 17.1117 29.1045 16.4723C28.5232 16.7048 27.9419 16.8792 27.3025 16.9374C27.9419 16.5304 28.407 15.9491 28.6395 15.1935C28.0582 15.5422 27.3606 15.8329 26.6631 15.9491C26.0818 15.3678 25.268 14.9609 24.396 14.9609C22.7102 14.9609 21.3151 16.3561 21.3151 18.0418C21.3151 18.2743 21.3151 18.5069 21.3733 18.7394C18.8155 18.6813 16.4903 17.4605 14.979 15.6004C14.7464 16.0654 14.572 16.5886 14.572 17.1699C14.572 18.2743 15.0952 19.2044 15.9672 19.7276C15.444 19.7276 14.979 19.5532 14.5139 19.3207C14.5139 19.3207 14.5139 19.3207 14.5139 19.3788C14.5139 20.8902 15.5603 22.1109 17.0135 22.4016C16.781 22.4597 16.4903 22.5178 16.1997 22.5178C16.0253 22.5178 15.7928 22.5178 15.6184 22.4597C16.0253 23.6804 17.1298 24.6105 18.5249 24.6105C17.4785 25.4243 16.1416 25.9475 14.6883 25.9475C14.4558 25.9475 14.1651 25.9475 13.9326 25.8894C15.3277 26.7613 16.9554 27.2845 18.6993 27.2845Z"
        fill="#060606"
      />
    </svg>
  )
}

export default Twitter
