import React from "react"
import moment from "moment"

import VideoModal from "../VideoModal"
import BlogPlay from "~/images/blog-play-icon.svg"
import DefaultPattern from "../patterns/DefaultPattern"

function VideoHero({ heading, video, category, date }) {
  return (
    <section className="relative pt-32 md:pt-40 lg:pt-56 bg-slate">
      <div className="container z-0">
        <div className="flex justify-center">
          <div className="max-w-[47.813rem]">
            <div className="flex text-10 text-white uppercase opacity-70 tracking-[0.05em] leading-[1.109] mb-7 justify-center">
              <div className="mr-8">{category}</div>
              <div>{moment(date).format("MMMM DD, YYYY")}</div>
            </div>{" "}
            <h1 className="text-center text-white u-h3">{heading}</h1>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 z-0 h-24 bg-light-grey ">
          <div className="relative w-full h-full">
            <DefaultPattern />
          </div>
        </div>
        <div className="z-10 w-full h-full mt-12 md:mt-24">
          <VideoModal
            imageClass="w-full h-full"
            blogPlay={BlogPlay}
            {...video}
            customVideo={video?.videoEmbed}
          />
        </div>
      </div>
    </section>
  )
}

export default VideoHero
