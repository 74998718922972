import React from "react"

function Blockquote({ name, quote, title }) {
  return (
    <div className="md:max-w-[43rem] mx-auto my-16">
      <div className="pl-6 border-l-4 md:pl-12 border-red ">
        <h3 className="font-medium leading-[1.269] text-24 md:text-35  ">
          {quote}
        </h3>
        <div className="mt-4">
          <div className="font-semibold leading-[1.40]">{name}</div>
          <div className="font-medium leading-[1.015] opacity-40 mt-px">
            {title}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blockquote
