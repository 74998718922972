import React from "react"
import DefaultButton from "../buttons/DefaultButton"
import moment from "moment"
import styled from "styled-components"
import tw from "twin.macro"
import Image from "../Image"
import DefaultPattern from "../patterns/DefaultPattern"

const WhitePaperHeroImage = styled.div`
  position: relative;
  z-index: 20;

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    right: -5%;
    bottom: -3%;
    background-color: #fff;
    z-index: -1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  &:before {
    ${tw`hidden md:block`}

    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    right: -10%;
    bottom: -6%;
    background-color: #fff;
    z-index: -2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
`

function WhitePaperHero({ date, category, heading, downloadFile, image }) {
  return (
    <section className="relative pt-32 pb-20 md:pt-40 lg:pt-56 md:pb-32 bg-slate">
      <div className="container relative z-0">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="md:max-w-[30rem] mr-12 md:w-[60%]">
            <div className="flex text-10 text-white uppercase opacity-70 tracking-[0.05em] leading-[1.109] mb-7 ">
              <div className="mr-8">{category}</div>
              <div>{moment(date).format("MMMM DD, YYYY")}</div>
            </div>
            <h1 className="mb-6 text-white u-h3">{heading}</h1>

            <DefaultButton
              download
              url={downloadFile?.localFile?.publicURL}
              containerClass="max-w-max"
              title="Download Now"
            />
          </div>

          <WhitePaperHeroImage className=" mt-12 md:mt-0 md:w-[40%]">
            <Image image={image} />
          </WhitePaperHeroImage>
        </div>
      </div>
    </section>
  )
}

export default WhitePaperHero
