import React, { useRef } from "react"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import SwiperCore, { Pagination, Navigation } from "swiper/core"
import useSwiperRef from "~/hooks/useSwiperRef"
import Image from "../Image"

SwiperCore.use([Pagination, Navigation])

function Carousel({ images, caption }) {
  const flexibleSlider = useRef(null)

  const [nextEl, next] = useSwiperRef()
  const [prevEl, prev] = useSwiperRef()
  return (
    <section className="my-16 ">
      <div className="">
        <Swiper
          className="flexible-slider"
          ref={flexibleSlider}
          grabCursor={true}
          pagination={{
            type: "fraction",
          }}
          navigation={{
            prevEl,
            nextEl,
          }}
        >
          {images?.map((image, i) => (
            <SwiperSlide
              slidesPerView={1}
              className="relative z-0 w-full"
              key={`careerSlide${i}`}
            >
              <Image image={image?.image} objectFit="cover" />

              <div className="mt-2 md:mt-5 opacity-40 text-14 md:text-16 max-w-[70%]">
                {image?.caption}
              </div>
            </SwiperSlide>
          ))}

          <div className="absolute top-[0%] left-0 z-10 flex justify-between w-full h-full  md:px-12">
            <div className="mt-20 cursor-pointer sm:my-auto " ref={prev}>
              <svg
                className="h-9 md:h-full"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="60"
                  width="60"
                  height="60"
                  rx="30"
                  transform="rotate(90 60 0)"
                  fill="black"
                />
                <rect
                  x="59.5"
                  y="0.5"
                  width="59"
                  height="59"
                  rx="29.5"
                  transform="rotate(90 59.5 0.5)"
                  stroke="black"
                  strokeOpacity="0.2"
                />
                <path
                  d="M39.479 31.0342L23.2482 31.0342L23.2482 28.9402L39.479 28.9402L39.479 31.0342Z"
                  fill="white"
                />
                <path
                  d="M27.8141 22.9673L29.2808 24.434L23.7301 29.9836L29.2808 35.5333L27.8141 37L20.7987 29.9836L27.8141 22.9673Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="mt-20 cursor-pointer sm:my-auto " ref={next}>
              <svg
                className="h-9 md:h-full"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="60"
                  width="60"
                  height="60"
                  rx="30"
                  transform="rotate(-90 0 60)"
                  fill="black"
                />
                <rect
                  x="0.5"
                  y="59.5"
                  width="59"
                  height="59"
                  rx="29.5"
                  transform="rotate(-90 0.5 59.5)"
                  stroke="black"
                  strokeOpacity="0.2"
                />
                <path
                  d="M20.521 28.9658H36.7518V31.0598H20.521V28.9658Z"
                  fill="white"
                />
                <path
                  d="M32.1859 37.0327L30.7192 35.566L36.2699 30.0164L30.7192 24.4667L32.1859 23L39.2013 30.0164L32.1859 37.0327Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </Swiper>
      </div>
    </section>
  )
}

export default Carousel
